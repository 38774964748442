import React, { useState } from 'react'
import DisplayIcon from '../../../public/assets/images/display.svg'
import VideoIcon from '../../../public/assets/images/video.svg'

//mui imports
import {
  TextField,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
  Box,
  Divider,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { Search } from '@mui/icons-material'
import { Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {},
  activateButton: {
    color: 'white',
    backgroundColor: '#EAAF3D',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'black',
    },
  },
  tableContainer: {
    //table width here
    paddingLeft: '2%',
    paddingRight: '2%',
    width: 'auto',
  },
  buttonText: {
    fontWeight: 'bold',
    paddingLeft: '1em',
    paddingRight: '1em',
  },
  floorPriceContainer: {
    display: 'flex',
  },
  iconHeight: {
    height: 20,
  },
  displayContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  videoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  costText: {
    paddingLeft: '1em',
    paddingRight: '1em',
  },
  headerText: {
    fontWeight: 'bold',
    color: '#606F89',
  },
  headerRow: {
    backgroundColor: '#F4F7FC',
  },
  searchAndCategoryContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchBar: {
    width: '50%',
    backgroundColor: '#F4F7FC',
  },
  categoryDropdown: {
    width: '20%',
  },
}))

const TABLE_ROW_HEADERS = [
  {
    key: 'curateddealname',
    value: 'DEAL NAME',
    align: 'left',
  },
  {
    key: 'category',
    value: 'CATEGORY',
    align: 'left',
    width: '20%',
  },
  {
    key: 'floorPrice',
    value: 'FLOOR PRICE',
    align: 'left',
    width: '10%',
  },
  {
    key: 'activate',
    align: 'right',
    width: '25%',
  },
]
const DEAL_CATEGORIES = [
  'All',
  'Automotive',
  'Consumer Electronics',
  'Demographic',
  'Food & Beverage',
  'Retail',
  'Service Providers',
  'Subscription Services',
]
const PAGE_LIMIT = 10

const DealsTable = ({ data, handleActivate }) => {
  const classes = useStyles()
  const [searchInput, setSearchInput] = useState('')
  const [page, setPage] = useState(0)
  const [category, setCategory] = useState('All')

  const handleSetPage = (_e, page) => {
    setPage(page)
  }

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value)
    setPage(0)
  }

  const handleSetCategory = (e) => {
    setCategory(e.target.value)
    setPage(0)
  }

  const filterDealByInput = (searchInput) => {
    return data.filter((deal) =>
      deal.curateddealname.toLowerCase().includes(searchInput)
    )
  }

  const getFilteredDeals = () => {
    if (category === 'All') {
      return filterDealByInput(searchInput)
    } else {
      return filterDealByInput(searchInput).filter(
        (row) => row.category === category
      )
    }
  }
  const filteredDeals = getFilteredDeals()

  return (
    <Paper className={classes.tableContainer}>
      <Box
        className={classes.searchAndCategoryContainer}
        paddingTop={1.5}
        paddingBottom={1.5}
      >
        <TextField
          className={classes.searchBar}
          placeholder="Search"
          onChange={handleSearchInput}
          defaultValue={searchInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="end">
                <Search color="#6F809E" />
              </InputAdornment>
            ),
          }}
        />
        <FormControl className={classes.categoryDropdown} variant="standard">
          <InputLabel id="format-selector-label">Category</InputLabel>
          <Select
            labelId="format-selector-label"
            id="format-selector"
            onChange={handleSetCategory}
            value={category}
          >
            {DEAL_CATEGORIES.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Divider />
      <Table>
        <TableHead>
          <TableRow className={classes.headerRow}>
            {TABLE_ROW_HEADERS.map((headers, index) => (
              <TableCell
                align={headers.align || 'center'}
                key={`header-${index}`}
              >
                <div className={classes.headerText}>{headers.value}</div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredDeals
            .slice(page * PAGE_LIMIT, page * PAGE_LIMIT + PAGE_LIMIT)
            .map((deal, index) => (
              <TableRow
                style={
                  index % 2
                    ? { backgroundColor: '#F4F7FC' }
                    : { backgroundColor: 'white' }
                }
                hover
                key={`body-row-${deal.id}`}
              >
                {TABLE_ROW_HEADERS.map((headers) => {
                  switch (headers.key) {
                    case 'floorPrice':
                      return (
                        <FloorPriceCell
                          width={headers.width}
                          align={headers.align}
                          key={`floorprice-${deal.id}`}
                        />
                      )
                    case 'activate':
                      return (
                        <TableCell
                          width={headers.width}
                          align={headers.align || 'center'}
                          key={`${deal.id}-${deal[headers.key]}`}
                        >
                          <Button
                            className={classes.activateButton}
                            color="primary"
                            disableElevation
                            variant="contained"
                            onClick={() => {
                              handleActivate(deal)
                            }}
                          >
                            <span className={classes.buttonText}>Activate</span>
                          </Button>
                        </TableCell>
                      )
                    default:
                      return (
                        <TableCell
                          width={headers.width}
                          align={headers.align || 'center'}
                          key={`${deal.id}-${deal[headers.key]}`}
                        >
                          <div>{deal[headers.key]}</div>
                        </TableCell>
                      )
                  }
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={filteredDeals.length}
        rowsPerPage={PAGE_LIMIT}
        page={page}
        onPageChange={handleSetPage}
        rowsPerPageOptions={[10]}
        showFirstButton
        showLastButton
      />
    </Paper>
  )
}

// eslint-disable-next-line react/display-name
const FloorPriceCell = React.memo(({ width, align, key }) => {
  const classes = useStyles()
  return (
    <TableCell width={width} align={align || 'center'} key={key}>
      <div className={classes.floorPriceContainer}>
        <div className={classes.displayContainer}>
          <img
            className={classes.iconHeight}
            src={DisplayIcon}
            alt="display-icon"
          />
          <div className={classes.costText}>$2</div>
        </div>
        <div className={classes.videoContainer}>
          <img
            className={classes.iconHeight}
            src={VideoIcon}
            alt="video-icon"
          />
          <div className={classes.costText}>$7</div>
        </div>
      </div>
    </TableCell>
  )
})

export default DealsTable
