import React from "react";
import { makeStyles } from "@material-ui/core";
import { AppBar} from "@mui/material";
import KloverLogo from '../../public/assets/images/KloverLogo.png'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    color: 'white',
  },
  appBar: {
    paddingLeft: '5%',
    paddingBottom: '5%'
  },
  header: {
    fontFamily: 'Playfair Display',
    fontWeight: 'bold',
    fontSize: 50,
    color: 'white',
    letterSpacing: -1
  },
  highlightedHeader: {
    color: '#EAAF3D'
  },
  description: {
    fontFamily: 'Open Sans',
    color: '#9893A3',
    fontSize: 20,
    marginTop: '-1em',
    paddingBottom: '2em'
  },
  logo: {
    width: 140,
    height: 'auto',
    paddingTop: '2.5%'
  },
  kloverLogo: {
    textDecoration: 'none'
  }
}))

const TopBar = React.memo((props) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} style={{background: '#262D3A'}} position="static">
        <a className={classes.kloverLogo} href='https://www.joinklover.com/partners'>
          <img className={classes.logo} src={KloverLogo} alt='klover-logo' />
        </a>
        <h1 className={classes.header}>
        <span className={classes.highlightedHeader}>Real</span> purchase data, <span className={classes.highlightedHeader}>better</span> outcomes.
        </h1>
        <p className={classes.description}>
          Reach your audience with verified spending history, receipt data, and consumer opinions -
          <br />
          100% cookieless and fully compliant. No account, commitment or subscription necessary.
        </p>
      </AppBar>
    </div>
  );
});

export default TopBar;
