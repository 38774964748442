import React from "react";
import { makeStyles } from "@material-ui/core";
import KloverLogo from '../../public/assets/images/KloverLogo.png'

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#262D3A',
    paddingTop: '13em',
  },
  bottomBarContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingLeft: '32.5%',
    paddingRight: '32.5%',
    paddingBottom: '2.5em',
    paddingTop: '2.5%',
    fontFamily: 'Open Sans',
    fontSize: 20,
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    width: 140,
    height: 'auto',
  },
  redirectLinks: {
    textDecoration: 'none',
    color: '#9893A3',
    '&:hover': {
      color: '#EAAF3D',
      fontWeight: 600
    }
  },
  kloverLogo: {
    textDecoration: 'none'
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '5em'
  },
  footer: {
    color: '#9893A3',
    fontSize: 20
  },
  emailFooter: {
    textDecoration: 'underline'
  }
}))

const BottomBar = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.logoContainer}>
        <a className={classes.kloverLogo} href='https://www.joinklover.com/partners'>
          <img className={classes.logo} src={KloverLogo} alt='klover-logo' />
        </a>
      </div>
      <div className={classes.bottomBarContainer}>
        <div>
          <a className={classes.redirectLinks} href='https://www.joinklover.com/partners'>Home</a>
        </div>
        <div>
          <a className={classes.redirectLinks} href='https://www.joinklover.com/careers'>Careers</a>
        </div>
        <div>
          <a className={classes.redirectLinks} href='https://www.joinklover.com/blog'>Blog</a>
        </div>
      </div>
      <div className={classes.footerContainer}>
        <div>
          <a className={classes.footer}>
            Have Questions? Email us at <span className={classes.emailFooter}>curateddeals@joinklover.com</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default BottomBar;
