import React, { useCallback, useState } from 'react'

//mui imports
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
  InputLabel,
  FormHelperText,
} from '@mui/material'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'

import _ from 'lodash'

const useStyles = makeStyles(() =>
  createStyles({
    dialogTitle: {
      minHeight: '70px',
      maxHeight: '70px',
      alignItems: 'center',
      padding: 0,
    },
    dialogTitleDivider: {
      color: '#EAAF3D',
      width: '4px',
      height: '70px',
    },
    dialogTitleTextContainer: {
      paddingLeft: '2.25em',
      paddingTop: '2em',
    },
    dialogText: {
      fontSize: 12,
      fontWeight: 600,
    },
    activateText: {
      fontFamily: 'Playfair Display',
      letterSpacing: -1,
      fontSize: 30,
      fontWeight: 'bold',
    },
    activateTextSubtitle: {
      fontSize: 16,
    },
    verticalLine: {
      border: 0,
      margin: 0,
      borderLeft: '4px solid #EAAF3D',
      height: 80,
      float: 'left',
    },
    textContainer: {
      paddingLeft: '1em',
    },
    dialogContainer: {
      paddingTop: 35,
    },
    textField: {
      width: 420,
      paddingBottom: 17,
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'left',
      paddingLeft: '3.75em',
      paddingBottom: '5em',
    },
    confirmationText: {
      fontFamily: 'Playfair Display',
      fontWeight: 'bold',
      letterSpacing: -1,
      fontSize: 30,
      paddingLeft: '0.5em',
    },
    confirmationTextContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      color: 'white',
      backgroundColor: '#EAAF3D',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'black',
      },
    },
    buttonText: {
      fontWeight: 'bold',
      paddingLeft: '1em',
      paddingRight: '1em',
    },
    backToDealsButton: {
      backgroundColor: 'black',
      color: 'white',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: 'black',
      }
    },
    confirmationSubtitle: {
      fontFamily: 'Open Sans',
      fontSize: 20,
    },
    email: {
      fontWeight: 600,
    },
    confirmationSubtitleContainer: {
      paddingTop: '4em',
    },
    confirmationContainer: {
      paddingLeft: '2.75em',
      paddingRight: '2.75em',
      paddingTop: '3.5em',
      paddingBottom: '2em',
    },
    textLabelContainer: {
      width: 420,
    },
    spinner: {
      height: 10,
      width: 10,
    },
    closeButtonContainer: {
      display: 'flex',
      justifyContent: 'right'
    },
    closeIcon: {
      color: 'black'
    },
    closeButton: {
      paddingLeft: '15.5em',
      '&:hover': {
        backgroundColor: 'transparent'
      }
    },
    textAndButton: {
      display: 'flex'
    },
  })
)

const MOCK_FORMATS = ['Display', 'Video', 'Both - Display & Video']
const DSPS = [
  'Adelphic (formerly known as Viant)',
  'Adform',
  'Amobee (formerly known as Turn)',
  'Amobee TV',
  'Deepintent',
  'DV360 (formerly known as DBM)',
  'Epsilon/Conversant',
  'iPromote',
  'Media.net',
  'MediaMath',
  'MSAN',
  'Nextroll (Adroll)',
  'Quantcast',
  'StackAdapt',
  'The Trade Desk',
  'Verizon Media Group',
  'Xandr',
  'Zemanta',
]
const EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

const DealsDialog = ({
  open,
  confirmed,
  deal,
  handleCloseDialog,
  onSubmit,
}) => {
  const [email, setEmail] = useState('')
  const [seatid, setSeatId] = useState('')
  const [dsp, setDsp] = useState('')
  const [format, setFormat] = useState('Both - Display & Video')
  const [advertiserbrand, setAdvertiserBrand] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const classes = useStyles()

  const handleSetEmail = useCallback((e) => {
    setEmail(e.target.value)
  }, [])

  const handleSetSeatId = useCallback((e) => {
    if (e.target.value.length <= 255) setSeatId(e.target.value)
  }, [])

  const handleSetDSP = useCallback((e) => {
    setDsp(e.target.value)
  }, [])

  const handleSetFormat = useCallback((e) => {
    setFormat(e.target.value)
  }, [])

  const handleSetAdvertiserBrand = useCallback((e) => {
    if (e.target.value.length <= 500) setAdvertiserBrand(e.target.value)
  }, [])

  const isValidEmail = (email) => {
    return EMAIL_REGEX.test(email.toLowerCase())
  }

  const validateInputs = () => {
    const errorMap = {}
    if (_.isEmpty(email)) {
      errorMap.email = 'This field is required'
    } else if (!isValidEmail(email)) {
      errorMap.email = 'Invalid Email'
    }
    if (_.isEmpty(dsp)) {
      errorMap.dsp = 'This field is required'
    }
    if (_.isEmpty(format)) {
      errorMap.format = 'This field is required'
    }
    if (_.isEmpty(advertiserbrand)) {
      errorMap.advertiserbrand = 'This field is required'
    }
    if (_.isEmpty(seatid)) {
      errorMap.seatid = 'This field is required'
    }
    setError(errorMap)
    return _.isEmpty(errorMap)
  }

  const handleSubmit = () => {
    if (validateInputs()) {
      setLoading(true)
      onSubmit(dsp, format, seatid, email, advertiserbrand, setLoading)
    }
  }

  return (
    <Dialog
      onClose={handleCloseDialog}
      open={open}
      PaperProps={{ sx: { width: '556px', height: '715px' } }}
    >
      {confirmed ? (
        <ConfirmedDialogContent
          email={email}
          handleCloseDialog={handleCloseDialog}
        />
      ) : (
        <React.Fragment>
          <DialogTitle className={classes.dialogTitle}>
            <div className={classes.dialogTitleTextContainer}>
              <hr className={classes.verticalLine} />
              <div className={classes.textContainer}>
                <div className={classes.textAndButton}>
                  <div className={classes.activateText}>Let's activate</div>
                  <div className={classes.closeButtonContainer}>
                    <Button className={classes.closeButton} onClick={handleCloseDialog}>
                      <div className={classes.closeIcon}>
                        <CloseIcon />
                      </div>
                    </Button>
                  </div>
                </div>
                <div className={classes.activateTextSubtitle}>
                  {deal.curateddealname}
                </div>
              </div>
            </div>
          </DialogTitle>
          <div className={classes.dialogContainer}>
            <DialogContent>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                width="100%"
              >
                <div className={classes.textField}>
                  <div className={classes.textLabelContainer}>
                    <div className={classes.dialogText}>Your Email</div>
                  </div>
                  <div className={classes.textLabelContainer}>
                    <TextField
                      placeholder="Enter Email here"
                      type="email"
                      value={email}
                      onChange={handleSetEmail}
                      fullWidth
                      sx={{
                        input: {
                          borderRadius: 30,
                          padding: '12px 14px',
                          fontFamily: 'Open Sans',
                        },
                      }}
                      helperText={error?.email}
                      error={error?.email}
                    />
                  </div>
                </div>
                <div className={classes.textField}>
                  <div className={classes.textLabelContainer}>
                    <div className={classes.dialogText}>Advertiser Brand</div>
                  </div>
                  <div className={classes.textLabelContainer}>
                    <TextField
                      placeholder=" Enter Advertiser Brand here"
                      value={advertiserbrand}
                      onChange={handleSetAdvertiserBrand}
                      fullWidth
                      sx={{
                        input: {
                          borderRadius: 8,
                          padding: '12px 14px',
                          fontFamily: 'Open Sans',
                        },
                      }}
                      helperText={error?.advertiserbrand}
                      error={error?.advertiserbrand}
                    />
                  </div>
                </div>
                <div className={classes.textField}>
                  <div className={classes.textLabelContainer}>
                    <div className={classes.dialogText}>DSP</div>
                  </div>
                  <div className={classes.textLabelContainer}>
                    <FormControl fullWidth>
                      <Select
                        labelId="dsp-selector-label"
                        id="dsp-selector"
                        onChange={handleSetDSP}
                        displayEmpty
                        value={dsp}
                        sx={{
                          height: 47,
                          root: {
                            borderRadius: 8,
                          },
                        }}
                        helperText={error?.dsp}
                        error={error?.dsp}
                      >
                        <MenuItem key={''} value={''} disabled>
                          Select your DSP
                        </MenuItem>
                        {DSPS.map((dsp) => (
                          <MenuItem key={dsp} value={dsp}>
                            {dsp}
                          </MenuItem>
                        ))}
                      </Select>
                      {error?.dsp && (
                        <FormHelperText error>{error.dsp}</FormHelperText>
                      )}
                    </FormControl>
                  </div>
                </div>
                <div className={classes.textField}>
                  <div className={classes.textLabelContainer}>
                    <div className={classes.dialogText}>Seat ID</div>
                  </div>
                  <div className={classes.textLabelContainer}>
                    <TextField
                      placeholder="Enter Seat ID here"
                      value={seatid}
                      onChange={handleSetSeatId}
                      fullWidth
                      sx={{
                        input: {
                          borderRadius: 8,
                          padding: '12px 14px',
                          fontFamily: 'Open Sans',
                        },
                      }}
                      helperText={error?.seatid}
                      error={error?.seatid}
                    />
                  </div>
                </div>
                <div className={classes.textField}>
                  <div className={classes.textLabelContainer}>
                    <div className={classes.dialogText}>Format</div>
                  </div>
                  <div className={classes.textLabelContainer}>
                    <FormControl fullWidth>
                      {!format && (
                        <InputLabel id="format-selector-label" shrink={false}>
                          Select the Format
                        </InputLabel>
                      )}
                      <Select
                        labelId="format-selector-label"
                        id="format-selector"
                        onChange={handleSetFormat}
                        value={format}
                        sx={{
                          height: 47,
                          root: {
                            borderRadius: 8,
                          },
                        }}
                        helperText={error?.format}
                        error={error?.format}
                      >
                        {MOCK_FORMATS.map((mock_format) => (
                          <MenuItem key={mock_format} value={mock_format}>
                            {mock_format}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </Box>
            </DialogContent>
          </div>
          <div className={classes.buttonContainer}>
            <DialogActions>
              <Button
                className={classes.button}
                onClick={handleSubmit}
                color="primary"
                disableElevation
                variant="contained"
              >
                {loading && (
                  <CircularProgress
                    style={{ height: 24, width: 24, color: 'white' }}
                  />
                )}
                <span className={classes.buttonText}>Send Deal</span>
              </Button>
            </DialogActions>
          </div>
        </React.Fragment>
      )}
    </Dialog>
  )
}

const ConfirmedDialogContent = ({ email, handleCloseDialog }) => {
  const classes = useStyles()
  return (
    <React.Fragment>
      <div className={classes.confirmationContainer}>
        <div className={classes.closeButtonContainer}>
          <Button className={classes.closeButton} onClick={handleCloseDialog}>
            <div className={classes.closeIcon}>
              <CloseIcon />
            </div>
          </Button>
        </div>
        <DialogTitle className={classes.dialogTitle}>
          <div className={classes.confirmationTextContainer}>
            <hr className={classes.verticalLine} />
            <div className={classes.confirmationText}>
              Your deal request has been submitted!
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <Box
            display="flex"
            alignItems="space-around"
            justifyContent="space-around"
            padding={1}
            flexDirection="column"
            width="100%"
          >
            <div className={classes.confirmationSubtitleContainer}>
              <div className={classes.confirmationSubtitle}>
                We will kick off the deal creation and will be in touch within
                24 hours.
                <br />
                <br />
                The email will be sent to:
                <br />
                <span className={classes.email}>{email}</span>
              </div>
            </div>
          </Box>
        </DialogContent>
      </div>
      <div className={classes.buttonContainer}>
        <DialogActions>
          <Button
            className={classes.backToDealsButton}
            variant="contained"
            onClick={handleCloseDialog}
          >
            <span className={classes.buttonText}>Go Back To Deals</span>
          </Button>
        </DialogActions>
      </div>
    </React.Fragment>
  )
}

export default DealsDialog
