import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '-10em'
  },
  largeContactContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  contactUsContainer: {
    width: '70%',
    display: 'flex',
    justifyContent: 'space-around',
    // backgroundColor: 'purple'
  },
  textAndButtonContainer: {
    backgroundColor: '#EAAF3D',
    display: 'flex',
    justifyContent: 'space-around',
  },
  textContainer: {
    width: '60%',
    paddingLeft: '5%',
    // backgroundColor: 'green'
  },
  buttonContainer: {
    paddingRight: '5%',
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    color: 'black',
    fontWeight: 'bold',
    fontFamily: 'Playfair Display',
    fontSize: 40,
    paddingTop: '0.5em'
    // backgroundColor: 'pink'
  },
  subtitle: {
    color: 'white',
    fontWeight: 600,
    fontFamily: 'Open Sans',
    fontSize: 20,
    paddingBottom: '2em',
    // backgroundColor: 'blue'
  },
  button: {
    backgroundColor: 'black',
    color: 'white',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'black',
    }
  },
  buttonText: {
    paddingLeft: '1em',
    paddingRight: '1em'
  },
  mailTo: {
    textDecoration: 'none'
  }
}))

const ContactUs = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.largeContactContainer}>
        <div className={classes.contactUsContainer}>
          <div className={classes.textAndButtonContainer}>
            <div className={classes.textContainer}>
              <h1 className={classes.title}>
                Not seeing the curated deal you want?
              </h1>
              <p className={classes.subtitle}>
                Build a custom deal with Klover and gain access to thousands more retailers, products, and services.
              </p>
            </div>
            <div className={classes.buttonContainer}>
              <a className={classes.mailTo} href='mailto:curateddeals@joinklover.com'>
                <Button className={classes.button} variant="contained">
                  <span className={classes.buttonText}>
                    Contact Us
                  </span>
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
