import { useState } from 'react'
import DealsDialog from './DealsDialog'
import DealsTable from './DealsTable'
import curateddeals from '../../curateddeals.json'
import axios from 'axios'
//mui
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '-4em',
    paddingBottom: '3em',
    paddingLeft: 55,
    paddingRight: 55,
  },
}))

const HomeView = () => {
  const classes = useStyles()
  const [selectedDeal, setSelectedDeal] = useState(undefined)
  const [openDialog, setOpenDialog] = useState(false)
  const [confirmed, setConfirmed] = useState(false)

  const handleOpenDialog = () => {
    setConfirmed(false)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setConfirmed(false)
  }

  const handleActivate = (deal) => {
    setSelectedDeal(deal)
    handleOpenDialog()
  }

  const onSubmit = async (
    dsp,
    format,
    seatid,
    email,
    advertiserbrand,
    setLoading
  ) => {
    try {
      let axiosInstance = axios.create({
        headers: {
          'Content-Type': 'application/json',
        },
      })
      axiosInstance
        .post(process.env.WORKER_URL, {
          curateddealname: selectedDeal.curateddealname,
          email: email.toLowerCase().trim(),
          seatid,
          category: selectedDeal.category,
          format,
          dsp,
          advertiserbrand: advertiserbrand.trim(),
        })
        .then(() => {
          setLoading(false)
          setConfirmed(true)
        })
    } catch (error) {
      const code = error.code
      const message = error.message
      const details = error.details
      console.log(code, message, details)
    }
  }

  return (
    <div className={classes.root}>
      <DealsTable data={curateddeals} handleActivate={handleActivate} />
      {openDialog && (
        <DealsDialog
          open={openDialog}
          confirmed={confirmed}
          deal={selectedDeal}
          handleCloseDialog={handleCloseDialog}
          onSubmit={onSubmit}
        />
      )}
    </div>
  )
}

export default HomeView
