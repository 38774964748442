import BottomBar from './components/BottomBar'
import Navigation from './components/Navigation'
import TopBar from './components/TopBar'
import { makeStyles } from "@material-ui/core";
import ContactUs from './components/ContactUs';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#F4F7FC'
  },
}))

const App = () => {
  const classes = useStyles()
  console.log('process', process.env.name)
  return (
    <div className={classes.root}>
      <div>
        <TopBar />
      </div>
      <div>
        <Navigation />
      </div>
      <div>
        <ContactUs />
      </div>
      <div>
        <BottomBar />
      </div>
    </div>
  )
}

export default App
